<template>
  <div>
    <div class="card bg-white">
      <div class="flex justify-content-end mb-2">
        <Button
          :loading="isLoadingSave"
          icon="pi pi-save"
          class="mr-2"
          :label="id > 0 ? 'Update' : 'Simpan'"
          @click="onConfirmSave(!v$.$invalid)"
        />
        <Button
          icon="pi pi-plus"
          class="p-button-outlined mr-2"
          label="Rincian"
          @click="onAddData"
        />
        <Button
          class="p-button-link"
          label="Kembali"
          @click="$router.push({ name: 'JurnalUmum' })"
        />
      </div>
      <div class="grid">
        <div class="col-8 md:col-6">
          <div class="formgrid grid">
            <div class="field col-12 md:col-3">
              <label>Tanggal</label>
              <Calendar
                v-model="form.ju_at"
                dateFormat="dd/mm/yy"
                class="w-full"
                input-class="w-full"
              />
            </div>
            <div class="field col-12 md:col-5">
              <label>No Bukti</label>
              <InputText
                v-model="form.ju_no"
                class="w-full"
                input-class="w-full"
                maxlength="50"
                disabled
              />
            </div>
            <div class="field col-12 md:col-12">
              <label>Keterangan</label>
              <InputText
                v-model="form.keterangan"
                class="w-full"
                :class="{
                  'p-invalid': v$.form.keterangan.$invalid && submitted,
                }"
              />
              <small
                v-if="
                  (v$.form.keterangan.$invalid && submitted) ||
                  v$.form.keterangan.$pending.$response
                "
                class="p-error"
                >{{ v$.form.keterangan.required.$message }}</small
              >
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <fx-table
          :items="form.details"
          :total="form.details.length"
          :loading="isLoading"
          :paginator="false"
          hasFooter
          showGridlines
        >
          <template #columns>
            <Column
              field="perkiraan"
              header="perkiraan"
              style="min-width: 8rem; background-color: #f8f9fa"
            />
            <Column
              field="keterangan"
              header="keterangan"
              style="min-width: 15rem; background-color: #f8f9fa"
            />
            <Column
              field="debit"
              header="debit"
              style="min-width: 10rem; justify-content: flex-end"
            >
              <template #body="slotProps">
                <span style="text-align: right">
                  {{ formatCurrency(slotProps.data.debit) }}
                </span>
              </template>
            </Column>
            <Column
              field="kredit"
              header="kredit"
              style="min-width: 10rem; justify-content: flex-end"
            >
              <template #body="slotProps">
                <span style="text-align: right">
                  {{ formatCurrency(slotProps.data.kredit) }}
                </span>
              </template>
            </Column>
            <Column style="flex-grow: 1; justify-content: flex-end">
              <template #body="{ data }">
                <Button
                  type="button"
                  icon="pi pi-pencil"
                  class="
                    p-button-outlined p-button-secondary p-button-text
                    mr-2
                  "
                  @click="onEditData(data)"
                />
                <Button
                  type="button"
                  icon="pi pi-trash"
                  class="p-button-outlined p-button-danger p-button-text"
                  @click="onConfirmDeletion(data)"
                />
              </template>
            </Column>
          </template>
          <template #footer>
            <table width="100%" class="foot">
              <tr>
                <td align="right" style="width: calc(100% - 120px)" colspan="2">
                  Total
                </td>
                <td align="right" class="text-right">
                  {{ formatCurrency(totalDebit, 2, 2) }}
                </td>
                <td align="right" class="text-right">
                  {{ formatCurrency(totalKredit, 2, 2) }}
                </td>
              </tr>
              <tr>
                <td align="right" style="width: calc(100% - 120px)" colspan="2">
                  Balance
                </td>
                <td align="right" class="text-right">
                  {{ formatCurrency(totalBalance, 2, 2) }}
                </td>
                <td align="right" class="text-right"></td>
              </tr>
            </table>
          </template>
        </fx-table>
      </div>
    </div>
    <!-- </form> -->
    <Dialog
      :header="editMode ? 'Edit Rincian' : 'Tambah Rincian'"
      v-model:visible="dialog"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '40vw' }"
      :modal="true"
    >
      <form-jurnal-umum-item
        :item="formDetail"
        :list-perkiraan-akun="listPerkiraanAkun"
        :loading="isLoadingSave"
        @save="onSaveData"
        @close="dialog = false"
      />
    </Dialog>
    <Dialog
      header="Hapus"
      v-model:visible="dialogHapus"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '50vw' }"
      :modal="true"
    >
      <div class="confirmation-content">
        <span
          >Item <strong>{{ formDetail.nama_simpanan }}</strong> akan dihapus.
          Proses ?</span
        >
      </div>
      <template #footer>
        <Button
          label="Hapus"
          icon="pi pi-trash"
          @click="onDeleteItem"
          class="p-button-text p-button-danger"
        />
      </template>
    </Dialog>
    <hotkey :shortcuts="['D', 'S']" @triggered="onTriggerHotkey" />
  </div>
</template>

<script>
import PerkiraanAkunService from '@/services/PerkiraanAkunService'
import JurnalUmumService from '@/services/JurnalUmumService'
import dayjs from 'dayjs'
import useVuelidate from '@vuelidate/core'
import errorHandler from '@/helpers/error-handler'
import FxTable from '@/components/FxTable'
import { helpers, required } from '@vuelidate/validators'
import { formatCurrency } from '@/helpers/index'
import FormJurnalUmumItem from '@/components/keuangan/FormJurnalUmumItem'
import Hotkey from '@/components/Hotkey'

export default {
  setup: () => ({ v$: useVuelidate() }),
  props: {
    id: {
      type: Number,
      default: 0,
    },
  },
  components: {
    FormJurnalUmumItem,
    FxTable,
    Hotkey,
  },
  data() {
    return {
      jurnalUmumService: null,
      submitted: false,
      dialog: false,
      dialogHapus: false,
      isLoading: false,
      isLoadingSave: false,
      items: [],
      listPerkiraanAkun: [],
      editMode: false,
      form: {
        ju_no: '',
        ju_at: new Date(),
        keterangan: '',
        details: [],
      },
      formDetail: {
        coa_item_id: '',
        debit: 0,
        kredit: 0,
        keterangan: '',
      },
      listDeleted: [],
    }
  },
  created() {
    this.jurnalUmumService = new JurnalUmumService()
  },
  async mounted() {
    const perkiraanAkunService = new PerkiraanAkunService()
    await perkiraanAkunService
      .get()
      .then((res) => {
        this.listPerkiraanAkun = res.data.data
      })
      .catch((err) => {
        errorHandler(err, 'Data Perkiraan', this)
      })

    if (this.id > 0) {
      this.isLoading = true
      this.jurnalUmumService
        .get(`/${this.id}`)
        .then((res) => {
          if (res.data.status === 200) {
            this.form = res.data.data
            this.form.ju_at = this.form.ju_at ? new Date(this.form.ju_at) : null

            // Loop through form.details and add perkiraan
            this.form.details.forEach((detail) => {
              const getPerkiraan = this.listPerkiraanAkun.find(
                (item) => item.id === detail.coa_item_id
              )
              detail.perkiraan = getPerkiraan ? getPerkiraan.nama : null
            })
          }
        })
        .catch((err) => {
          errorHandler(err, 'Jurnal Umum', this)
        })
        .finally(() => (this.isLoading = false))
    }
  },
  computed: {
    totalDebit() {
      return this.form.details.reduce((total, item) => total + item.debit, 0)
    },
    totalKredit() {
      return this.form.details.reduce((total, item) => total + item.kredit, 0)
    },
    totalBalance() {
      return this.totalDebit - this.totalKredit
    },
  },
  watch: {},
  methods: {
    formatCurrency(val) {
      return formatCurrency(val, 0, 0)
    },
    selectAllText(event) {
      if (event) {
        this.$refs[event].$el.querySelector('input').select()
      }
    },
    onAddData() {
      this.editMode = false
      ;(this.formDetail = {
        coa_item_id: '',
        debit: 0,
        kredit: 0,
        keterangan: '',
      }),
        (this.dialog = true)
    },
    async onEditData(data) {
      this.editMode = true
      this.formDetail = Object.assign({}, data)
      this.dialog = true
    },
    async onSaveData(data) {
      const detailItem = Object.assign({}, data)

      const idx = this.form.details.findIndex(
        (item) => item.coa_item_id === detailItem.coa_item_id
      )

      if (this.editMode) {
        Object.assign(this.form.details[idx], { ...detailItem })
        this.dialog = this.editMode ? false : this.dialog
      } else {
        const getPerkiraan =
          this.listPerkiraanAkun.filter(
            (item) => item.id === detailItem.coa_item_id
          ).length > 0
            ? this.listPerkiraanAkun.filter(
                (item) => item.id === detailItem.coa_item_id
              )[0]
            : {}
        const payload = {
          perkiraan: getPerkiraan.nama,
          ...detailItem,
        }

        this.form.details.push(payload)
        this.$toast.add({
          severity: 'success',
          summary: 'Jurnal',
          detail: 'Item ' + payload.perkiraan + ' berhasil ditambahkan.',
          life: 3000,
        })
        this.formDetail = {
          coa_item_id: '',
          debit: 0,
          kredit: 0,
          keterangan: '',
        }
      }
    },
    onConfirmDeletion(data) {
      this.formDetail = data
      this.dialogHapus = true
    },
    onDeleteItem() {
      const index = this.form.details.findIndex(
        (el) => el.coa_item_id === this.formDetail.coa_item_id
      )
      if (index !== -1) {
        if (this.id && this.form.details[index].id) {
          this.listDeleted.push(this.form.details[index].id)
        }
        this.form.details.splice(index, 1)
        this.$toast.add({
          severity: 'success',
          summary: 'Data Item',
          detail: 'Data berhasil dihapus.',
          life: 3000,
        })
      }
      this.dialogHapus = false
    },
    onConfirmSave(isFormValid) {
      this.submitted = true

      if (!isFormValid) {
        return
      }

      if (this.totalBalance > 0) {
        this.$toast.add({
          severity: 'warn',
          summary: 'Jurnal Umum',
          detail: `Jurnal belum balance. Selisih sebesar ${formatCurrency(
            this.totalBalance,
            2,
            2
          )}`,
          life: 3000,
        })
        return
      }

      const deleted = this.id ? { deleted: this.listDeleted } : {}

      const ju_at = this.form.ju_at
        ? { ju_at: dayjs(this.form.ju_at).format('YYYY-MM-DD') }
        : null
      const form = {
        ju_no: this.form.ju_no,
        keterangan: this.form.keterangan, 
        ...ju_at,
        details: this.form.details.map((item) => {
          const id = item.id ? { id: item.id } : this.id ? { id: 0 } : {}
          return {
            ...id,
            coa_item_id: item.coa_item_id,
            keterangan: item.keterangan,
            debit: item.debit,
            kredit: item.kredit,
          }
        }),
        ...deleted,
      }

      if (!this.id) {
        console.log('cek form', form)
        this.isLoadingSave = true
        this.jurnalUmumService
          .add(form)
          .then((res) => {
            if (res.data.status === 200) {
              this.$toast.add({
                severity: 'success',
                summary: 'Simpanan',
                detail: 'Data berhasil disimpan.',
                life: 3000,
              })
              this.$router.push({ name: 'Simpanan' })
            }
          })
          .catch((err) => {
            errorHandler(err, 'Simpanan', this)
          })
          .finally(() => {
            this.deleted = []
            this.isLoadingSave = false
          })
      } else {
        this.isLoadingSave = true
        this.jurnalUmumService
          .update(this.id, form)
          .then((res) => {
            console.log('cek', res.data.status)
            if (res.data.status === 200) {
              this.$toast.add({
                severity: 'success',
                summary: 'Jurnal Umum',
                detail: 'Data berhasil diupdate.',
                life: 3000,
              })
              this.$router.push({ name: 'JurnalUmum' })
            }
          })
          .catch((err) => {
            errorHandler(err, 'Jurnal Umum', this)
          })
          .finally(() => {
            this.isLoadingSave = false
          })
      }
    },
    onTriggerHotkey(payload) {
      switch (payload.keyString) {
        case 'D':
          this.add()
          break
        case 'S':
          this.save(!this.v$.$invalid)
          break
      }
    },
  },
  validations() {
    return {
      form: {
        keterangan: {
          required: helpers.withMessage('Keterangan harus diisi.', required),
        },
      },
    }
  },
}
</script>
