<template>
  <div>
    <!-- Untuk mencegah tekan enter langsung submit -->
    <form @submit.prevent="handleSubmit(!v$.$invalid)">
      <div class="grid">
        <div class="col-12">
          <div class="formgrid grid">
            <div class="field col-12 md:col-12">
              <label>Perkiraan Akun</label>
              <Dropdown
                v-model="form.coa_item_id"
                :filter="true"
                :options="listPerkiraanAkun"
                class="w-full"
                input-class="w-full"
                optionValue="id"
                :optionLabel="perkiraanLabel"
                placeholder="Perkiraan Akun"
                :class="{
                  'p-invalid': v$.form.coa_item_id.$invalid && submitted,
                }"
              >
              </Dropdown>
              <small
                v-if="
                  (v$.form.coa_item_id.$invalid && submitted) ||
                  v$.form.coa_item_id.$pending.$response
                "
                class="p-error"
              >
                {{ v$.form.coa_item_id.required.$message }}
              </small>
            </div>
            <div class="field col-12 md:col-12">
              <label>Keterangan</label>
              <InputText
                v-model="form.keterangan"
                class="w-full"
                input-class="w-full"
                :class="{
                  'p-invalid': v$.form.coa_item_id.$invalid && submitted,
                }"
              />
              <small
                v-if="
                  (v$.form.keterangan.$invalid && submitted) ||
                  v$.form.keterangan.$pending.$response
                "
                class="p-error"
                >{{ v$.form.keterangan.required.$message }}</small
              >
            </div>
            <div class="field col-12 md:col-12">
              <label>Debit</label>
              <div class="p-inputgroup flex-1">
                <span class="p-inputgroup-addon"> Rp </span>
                <InputNumber
                  ref="debit"
                  v-model="form.debit"
                  class="w-full"
                  input-class="w-full"
                  locale="id-ID"
                  :min-fraction-digits="0"
                  :max-fraction-digits="0"
                  :allow-empty="false"
                  :disabled="this.form.kredit > 0"
                  :class="{ 'p-invalid': v$.form.debit.$invalid && submitted }"
                  @focus="selectAllText('debit')"
                >
                </InputNumber>
              </div>
              <small v-if="v$.form.debit.$invalid && submitted" class="p-error">
                {{ v$.form.debit.required.$message }}
              </small>
            </div>
            <div class="field col-12 md:col-12">
              <label>Kredit</label>
              <div class="p-inputgroup flex-1">
                <span class="p-inputgroup-addon"> Rp </span>
                <InputNumber
                  ref="kredit"
                  v-model="form.kredit"
                  class="w-full"
                  input-class="w-full"
                  locale="id-ID"
                  :min-fraction-digits="0"
                  :max-fraction-digits="0"
                  :allow-empty="false"
                  :disabled="this.form.debit > 0"
                  :class="{ 'p-invalid': v$.form.kredit.$invalid && submitted }"
                  @focus="selectAllText('kredit')"
                >
                </InputNumber>
              </div>
              <small
                v-if="v$.form.kredit.$invalid && submitted"
                class="p-error"
              >
                {{ v$.form.kredit.required.$message }}
              </small>
            </div>
          </div>
        </div>
      </div>
      <div class="flex justify-content-end align-items-end">
        <Button
          label="Tutup"
          icon="pi pi-times"
          @click="$emit('close')"
          class="p-button-text mr-2"
        />
        <Button
          :loading="loading"
          :label="editMode ? 'Ubah' : 'Tambahkan'"
          icon="pi pi-check"
          type="submit"
        />
      </div>
    </form>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { helpers, required } from '@vuelidate/validators'

export default {
  setup: () => ({ v$: useVuelidate() }),
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default() {
        return {}
      },
    },
    editMode: {
      type: Boolean,
      default: false,
    },
    listPerkiraanAkun: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      submitted: false,
    }
  },
  created() {},
  async mounted() {},
  computed: {
    form() {
      return this.item
    },
  },
  methods: {
    selectAllText(event) {
      if (event) {
        this.$refs[event].$el.querySelector('input').select()
      }
    },
    perkiraanLabel(e) {
      return e.kode + ' - ' + e.nama
    },
    handleSubmit(isFormValid) {
      this.submitted = true
      if (!isFormValid) {
        return
      }
      this.$emit('save', this.form)
      this.submitted = false
    },
  },
  validations() {
    return {
      form: {
        coa_item_id: {
          required: helpers.withMessage('Perkiraan harus diisi.', required),
        },
        keterangan: {
          required: helpers.withMessage('Keterangan harus diisi.', required),
        },
        debit: {
          required: helpers.withMessage('Debit harus diisi.', required),
        },
        kredit: {
          required: helpers.withMessage('Kredit harus diisi.', required),
        },
      },
    }
  },
}
</script>
