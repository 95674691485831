<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div>
    <div class="mb-4 flex justify-content-between align-items-center">
      <span v-if="id > 0" class="pr-2 mr-2 font-bold text-2xl">
        Jurnal Umum  - Edit</span
      >
      <span v-else class="pr-2 mr-2 font-bold text-2xl">
        Jurnal Umum  - Tambah</span
      >
      <div>
        <i class="pi pi-home mr-2"></i>
        <span v-if="id > 0" class="font-small">/ Jurnal Umum  - Edit </span>
        <span v-else class="font-small">/ Jurnal Umum  - Tambah</span>
      </div>
    </div>
    <form-jurnal-umum :id="id" />
  </div>
</template>

<script>
import FormJurnalUmum from '@/components/keuangan/FormJurnalUmum'

export default {
  name: 'JurnalUmumAdd',
  components: {
    FormJurnalUmum,
  },
  data() {
    return {
      id: this.$route.params.id ? parseInt(this.$route.params.id) : 0 ,
    }
  },
}
</script>
